import styles from "./Instagram.module.css"
import Carousel from 'react-img-carousel';

require('react-img-carousel/lib/carousel.css');
require('./Instagram.module.css');

const Instagram = () => {
	const images = require.context('../../../public/img/instagram', true);
	const imageList = images.keys().map(image => images(image)).reverse();

	return (
		<div className={styles["Instagram-layout"]}>
			<div className={styles['instagram-tag']} onClick={() => window.open('https://instagram.com/imalinzvinca')}>
				<div className={styles['instagram-tag--picture']}>

					<div className="image_outer_container">
						<div className="image_inner_container">
							<img src={'./img/instaProfile2.jpg'} />
						</div>
					</div>

				</div>
				<div className={styles['instagram-tag--details']}>
					<div className={styles['instagram-tag--name']}>imalinzvinca</div>
					<div className={styles['instagram-tag--location']}>Iasi, Romania</div>
				</div>
				<div className={styles['instagram-icon']}>
					<img src={'./img/instagram.png'} />

				</div>
			</div>
			<Carousel slideHeight="400px" autoplay={true} arrows={false} slideWidth="320px" infinite={false} cellPadding={2} lazyLoad={false} className={`${styles['myInstaCarousel']} ${imageList.length >= 5 ? 'more' : ''}`}>
				{imageList.map((image, index) => (
					<img key={index} src={image} alt={`image-${index}`} style={{ height: '100%', width: '100%', objectFit: 'cover' }} />
				))}
			</Carousel>
		</div>
	)
}

export default Instagram