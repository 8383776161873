// import styles of this component
import styles from "./Youtube.module.css"
import YouTube from 'react-youtube';

const Youtube = ({ video }) => {
	let videoId = video.split('=');

	videoId = videoId[1]

	const opts = {
		width: '100%',
		playerVars: {
			// https://developers.google.com/youtube/player_parameters
			autoplay: 0,
			muted: 1,
			volume: 0,
		},
	};

	const _onReady = (event) => {
		// access to player in all event handlers via event.target
		event.target.pauseVideo();
	}

	return (
		<div className={styles['youtube-wrapper']}>
			<h2 className={styles["youtube-title"]} onClick={() => window.open('https://www.youtube.com/@AlinZvinca', '_blank').focus()}>I do <u>youtube</u> too!</h2>
			<YouTube className={styles['youtube-video']} videoId={videoId} opts={opts} muted={true} volume={0} autoplay={false} onReady={_onReady} />
		</div>
	)
}

export default Youtube 