import styles from "./BrickLayout.module.css"

const BrickLayout = () => {
  return (
    <div className={styles["brick-layout"]}>
      <div className={styles["brick-column"]}>
        <img src="img/product/compressed/pictures/product14.jpeg" alt="" />
        <img src="img/lifestyle/compressed/pictures/speak1.jpeg" alt="" />
        <img src="img/product/compressed/pictures/product1.jpeg" alt="" />
        <img src="img/product/compressed/pictures/cola3.jpeg" alt="" />
      </div>
      <div className={styles["brick-column"]}>
        <img src="img/automotive/compressed/pictures/Alex8R-19.jpeg" alt="" />
        <img src="img/automotive/compressed/pictures/automotive30.jpeg" alt="" />
        <img src="img/automotive/compressed/pictures/automotive23.jpeg" alt="" />
        <img src="img/automotive/compressed/pictures/automotive11.jpeg" alt="" />
      </div>
      <div className={styles["brick-column"]}>
        <img src="img/lifestyle/compressed/pictures/lavanda-9-min.jpeg" alt="" />
        <img src="img/lifestyle/compressed/pictures/lifestyle6.jpeg" alt="" />
        <img src="img/lifestyle/compressed/pictures/lifestyle8.jpeg" alt="" />
        <img src="img/product/compressed/pictures/revuele1.jpeg" alt="" />
      </div>
    </div>
  )
}

export default BrickLayout